import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FaFacebook } from "react-icons/fa"
import { Helmet } from "react-helmet"

export default function Home() {
  const title = "Fotogelmės.lt"
  const description = "asmeninės ir komercinės fotografijos studija"

  return (
    <>
      {/* <Seo title="Home" /> */}
      <Helmet
        htmlAttributes={{ lang: "lt" }}
        title={`${title}`}
        meta={[{ name: `description`, content: description }]}
      ></Helmet>
      <section className="h-screen">
        <div className="w-full h-screen flex flex-wrap">
          <div className="w-full sm:w-1/2 lg:w-2/5 flex flex-col my-auto">
            <div className="p-8 md:px-12 lg:px-16 font-family-karla">
              <StaticImage
                src="../images/fotogelmes-01.png"
                alt="A dinosaur"
                placeholder="blurred"
                layout="constrained"
                width={500}
                className="mb-8 w-60"
              />
              <h1 className="text-2xl font-semibold">Fotogelmės</h1>
              <h2 className="font-semibold">
                asmeninės ir komercinės fotografijos studija
              </h2>
              <p className="mb-2">
                Bažnyčios g. 2 Lentvaris,
                <br /> LT-25116
              </p>
              <p className="mb-2">
                Darbo laikas
                <br />
                II-V: 10:30 - 18:30
                <br /> VI-I: Pagal išankstinį susitarimą
              </p>
              <p>
                <a className="hover:text-blue-700" href="tel:004361473799">
                  +370 614 73799
                </a>
              </p>
              <p className="mb-2">
                <a
                  className="hover:text-blue-700"
                  href="mailto:dovile.berlinskaite@gmail.com"
                >
                  dovile.berlinskaite@gmail.com
                </a>
              </p>
              <div>
                <a
                  href="https://www.facebook.com/fotogelmes"
                  rel="nofollow noreferrer"
                  className="hover:text-blue-700"
                  target="_blank"
                >
                  <FaFacebook className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>

          <div className="bg-gray-900 w-full min-h-screen sm:w-1/2 lg:w-3/5">
            <div className="w-full">
              {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2308.729553334691!2d25.045745415772952!3d54.6439802802714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ddf3617ed6874f%3A0x98e4965b0caa6a41!2sFotogelm%C4%97s!5e0!3m2!1sen!2sat!4v1633371188803!5m2!1sen!2sat"
              width="600"
              height="600"
              allowfullscreen=""
              loading="lazy"
              className="w-full h-screen"
              title="location on Google Maps"
            ></iframe> */}

              <iframe
                src="https://snazzymaps.com/embed/343214"
                width="100%"
                height="600px"
                className="w-full h-screen"
                title="location on Google Maps"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
